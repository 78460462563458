import { ValidationController } from "stimulus-validation"

export class FormBaseController extends ValidationController {
  static targets = ["form"]
  static validatorOptions = { fullMessages: false }

  connect() {
    super.connect()
    this.disableDoubleSubmissionGuardsByRails()
  }

  submitForm(ev) {
    this.validateAll(ev)

    if (this.errors.hasAny()) {
      ev.preventDefault()
      return false
    }

    this.restoreDoubleSubmissionGuardsByRails()
    return true
  }

  afterValidate({ el, attr }) {
    const errorMsg = this.errorMessage(attr)

    errorMsg
      ? this.formGroup(el).classList.add("field_with_errors")
      : this.formGroup(el).classList.remove("field_with_errors")

    this.errorMessageEl(el).textContent = errorMsg
  }

  formGroup(el) {
    return el.closest(".form-group")
  }

  errorMessageEl(el) {
    return this.formGroup(el).querySelector(".help-block")
  }

  errorMessage(attr) {
    return this.errors.has(attr) ? this.errors.get(attr)[0] : ""
  }

  // NOTE: Workaround to avoid having Rails disabling the submit buttons automatically.
  // We want to allow it only when the form is valid and ready to be submitted.
  disableDoubleSubmissionGuardsByRails() {
    this.submitEls = this.formTarget.querySelectorAll("input[type='submit']", "button[type='submit']")

    this.submitEls.forEach(el => {
      if (el.dataset.disableWith) {
        el.dataset.disableWithBkp = el.dataset.disableWith
        delete el.dataset.disableWith
      }
    })
  }

  restoreDoubleSubmissionGuardsByRails() {
    this.submitEls.forEach(el => {
      if (el.dataset.disableWithBkp) {
        el.dataset.disableWith = el.dataset.disableWithBkp
      }
    })
  }
}
