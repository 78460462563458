import { loadStripe } from '@stripe/stripe-js';

document.addEventListener('turbo:load',  async function() {
  await loadStripe(process.env.STRIPE_API_KEY).then(function(stripe) {

    const elements = stripe.elements();
    const card = elements.create('card');
    const displayError = document.getElementById('card-errors');
    const form = document.getElementById("payment-form");
    const xhr = new XMLHttpRequest();

    const showCardError = (message) => {
      displayError.textContent = message;
      displayError.classList.remove('d-none');
    }

    const createPaymentIntent = function() {
      xhr.open("POST", '/donations/payment_intent', true);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.onreadystatechange = chargeCreditCard

      let amount_cents = form.querySelector('input[name="amount_cents"]:checked').value;
      let confirmMessage = `You are donating ${amount_cents / 100} USD to Skilling America. Please confirm.`

      dataConfirmModal.confirm({
        title: 'Your donation',
        text: confirmMessage,
        commit: 'Donate',
        cancel: 'Cancel',
        zIindex: 10099,
        onConfirm: donate,
        commitClass: 'btn-primary'
      });

    }

    const donate = () => {
      let receipt_email = form.querySelector('input#email').value;
      let amount_cents = form.querySelector('input[name="amount_cents"]:checked').value;
      let interest_area = document.getElementById('interest_area').value;

      xhr.send(
        `receipt_email=${encodeURIComponent(receipt_email)}` +
        `&amount_cents=${amount_cents}` +
        `&interest_area=${interest_area}` +
        `&authenticity_token=${encodeURIComponent(Rails.csrfToken())}`
      );
    }

    const chargeCreditCard = (e) => {
      if (e.target.readyState === XMLHttpRequest.DONE && e.target.status === 200) {
        let clientSecret = JSON.parse(e.target.response).secret

        stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: card,
            billing_details: {
              name: form.querySelector('#name').value,
              email: form.querySelector('#email').value,
              address: {
                line1: form.querySelector('#address-line1').value,
                line2: form.querySelector('#address-line2').value,
                city: form.querySelector('#address-city').value,
                state: form.querySelector('#address-state').value,
                postal_code: form.querySelector('#address-zip').value,
                country: form.querySelector('#address-country').value,
              },
            },
          },
        }).then(function(result) {
          if (result.error) {
            showCardError(result.error.message);
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              let thankYou = document.getElementById("thank-you");
              thankYou.classList.remove('d-none');
              thankYou.scrollIntoView();
              card.clear();
              form.reset();
            }
          }
        });
      }
    }

    if ($('#card-element').length > 0) {
      card.mount('#card-element');
    }

    card.on('change', function(event) {
      if (event.error) {
        showCardError(event.error.message)
      } else {
        displayError.classList.add('d-none');
        displayError.textContent = '';
      }
    });

    if (form) {
      form.addEventListener('submit', function(ev) {
        ev.preventDefault();

        if (card._empty) {
          showCardError('Please provide credit card details')
        } else if (card._invalid) {
          showCardError('Please provide valid credit card details')
        } else {
          createPaymentIntent()
        }
      });
    }
  });
});
