/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import './application.css'

// JavaScript packages
import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo

import 'modules/awesomplete'
import 'modules/trix'
import "modules/bootstrap"
import 'modules/bootstrap-select'
import 'modules/stripe'
import 'modules/contact'
import 'modules/filterrific'
import 'modules/donate'

import ContentEditable from "textcomplete.contenteditable"

global.Textcomplete || (global.Textcomplete = {})
global.Textcomplete.editors || (global.Textcomplete.editors = {})
global.Textcomplete.editors.ContentEditable = ContentEditable

require('data-confirm-modal')

import { Application } from "stimulus"
import ScrollTo from "stimulus-scroll-to"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))
application.register("scroll-to", ScrollTo)
