import Cookies from "js-cookie"

document.addEventListener('turbo:load', function() {
  const contactButton = document.querySelector('.contact-link')
  if (!contactButton) return

  if (Cookies.get('contactRequestSent')) {
    contactButton.classList.add('disabled')
    contactButton.text = 'Request Submitted'
  }

  contactButton.addEventListener('click', function(event) {

    if (contactButton.classList.contains('disabled')) {
      event.preventDefault()
      return
    }

    contactButton.classList.add('disabled')
    contactButton.text = 'Submitting...'
    Cookies.set('contactRequestSent', 'true', { expires: 30 })
  })
})
