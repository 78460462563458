import 'awesomplete'

function init_awesomplete() {
  const $dom = document.getElementById('sign_up_form_organization_name');
  if ($dom && $dom.classList.contains('agency')) {
    new Awesomplete($dom)
  }
}

document.addEventListener('turbo:load',  function() {
  init_awesomplete()
});

document.addEventListener('turbo:render',  function() {
  init_awesomplete()
});