import { loadStripe } from '@stripe/stripe-js';

document.addEventListener('turbo:load',  function() {
  // var checkoutButton = document.querySelector("#san-payment-link:not([disabled])");
  // if (checkoutButton) {
  $('.payment-link:not([disabled])').on('click', async function (event) {
    event.preventDefault();
    
    const product = $(this).data('product');
    const email = $(this).data('email');
    const success = $(this).data('success');
    const cancel = $(this).data('cancel')

    const showPaymentError = (error) => {
      var displayError = document.getElementById('error-message');
      displayError.classList.remove("d-none");
      displayError.textContent = error.message;
    };

    await loadStripe(process.env.STRIPE_API_KEY).then(function(stripe) {
      const redirectToCheckout = (e) => {
        if (e.target.readyState === XMLHttpRequest.DONE && e.target.status === 200) {
          let sessionId = JSON.parse(e.target.response).session_id

          stripe.redirectToCheckout({
            sessionId: sessionId
          })
          .then(function(result) {
            if (result.error) {
              showPaymentError(result.error)
            }
          })
          .catch(function (error) {
            if (error) {
              showPaymentError(error)
            }
          });
        }
      }

      let xhr = new XMLHttpRequest();
      xhr.open("POST", '/payment/checkout_session', true);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.onreadystatechange = redirectToCheckout
      xhr.send(
        `authenticity_token=${encodeURIComponent(Rails.csrfToken())}` + 
        `&product=${product}` +
        `&success=${encodeURIComponent(success)}` +
        `&cancel=${encodeURIComponent(cancel)}` +
        `&email=${encodeURIComponent(email)}`);
    });
  });
});
