import { FormBaseController } from "./form_base_controller"

export default class extends FormBaseController {
  static rules = {
    first_name: { presence: { allowEmpty: false } },
    last_name: { presence: { allowEmpty: false } },
    organization_name: { presence: { allowEmpty: false } },
    organization_size: { presence: { allowEmpty: false } },
    work_email: { presence: { allowEmpty: false } },
    work_phone: { presence: { allowEmpty: false } },
    reason: { presence: { allowEmpty: false } },
  }
}
